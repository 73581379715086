

(function($){
    "use strict";
    $('.clickable')
        .has('a')
        .css('cursor', 'pointer')
        .bind('click',function() {
            var href = $('a', this).attr('href');
            if($('a', this).is('[rel*=external]')){
                window.open(href);
                return false;
            } else {
                window.location = href;
            }
        });



    const hamburgers = $(".hamburger");
    if (hamburgers.length > 0) {
        hamburgers.on('click',function () {
          $(this).toggleClass('is-active');
          $(this).parent().toggleClass('is-active');
          hamburgers.next().fadeToggle();
          $('.mobile-filter').fadeToggle();
        });
    }

    const profile_dropdown = $(".account .dropdown");
    if (profile_dropdown.length > 0) {
        profile_dropdown.on('click',function () {
            $(this).toggleClass('is-active');
            $(this).next().slideToggle();
        });
    }

    const gelijkeHoogteActueel = $('.actueel');
    if(gelijkeHoogteActueel.length > 0){
        var grootste = 0;
        var item = $('.entry',gelijkeHoogteActueel);
        item.each(function () {
            var hoogte = $('.content',this).outerHeight();
            if(hoogte > grootste){
                grootste = hoogte;

            }
        });
        var minheight = grootste;
        if(gelijkeHoogteActueel.hasClass('btns')){
            minheight = grootste + 40;
        }
        $('.content',item).css('min-height',minheight+'px');
    }
    const gelijkeHoogteAgenda = $('.agenda');
    if(gelijkeHoogteAgenda.length > 0){
        var grootste = 0;
        var item = $('.entry',gelijkeHoogteAgenda);
        item.each(function () {
            var hoogte = $('.content',this).outerHeight();
            if(hoogte > grootste){
                grootste = hoogte;

            }
        });
        var minheight = grootste;
        if(gelijkeHoogteAgenda.hasClass('btns')){
            minheight = grootste + 40;
        }
        $('.content',item).css('min-height',minheight+'px');
    }

    const ledenPagina = $('.page-template-page-leden .um-directory');
    if(ledenPagina.length > 0){
        const zoekLedenKnop = $('a.um-button.um-do-search');
        zoekLedenKnop.removeAttr('class');
        zoekLedenKnop.addClass('knop blauw invert');
        zoekLedenKnop.text('ZOEKEN');

        zoekLedenKnop.on('click',function (eventObject) {
            eventObject.preventDefault();
            jQuery(this).parents('form').submit();
            return false;
        });

        const lid = $('.um-member',ledenPagina);
        lid.each(function () {
        $(this).has('a')
                .css('cursor', 'pointer')
                .bind('click',function() {
                    var href = $('a', this).attr('href');
                    if($('a', this).is('[rel*=external]')){
                        window.open(href);
                        return false;
                    } else {
                        window.location = href;
                    }
                });
        });

    }

    const loginAlert = $('.loginalert');
    if (loginAlert.length > 0){
        loginAlert.fadeIn();

        setTimeout(function () {
            loginAlert.fadeOut();
        },5000);

        loginAlert.on('click',function () {
            $(this).fadeOut();
        });
    }

    const agendaRSVP = window.rsvp;
    if(agendaRSVP){
        const aanpassen = $('[id^=rsvpStep]');
        if(aanpassen){
            const jaRadio = $('#mainRsvpY');
            const neeRadio = $('#mainRsvpN');
            //if(agendaRSVP.sub === 'Y'){
            jaRadio.prop('checked',true);
            aanpassen.val('editattendee');
            aanpassen.closest('form').submit();
            //}
            if(agendaRSVP.unsub === 'Y'){
                // aanpassen.val('editattendee');
                // aanpassen.closest('form').submit();
                neeRadio.prop('checked',true);
            }
        }
        $(window).load(function () {
            $('.loading').fadeOut();
        });
    }
}(jQuery));
